<template>
    <el-dialog append-to-body
            :title="title"
            :show="show"
            :visible.sync="visible"
            @close="$emit('update:show', false)"
            width="480px"
    >
        <div class="dialog-body">
            <div class="body-top">
                <label-span label="供应商名称：" :span="cooperateData.SupplierName"></label-span>
                <label-span label="供应商抬头：" :span="cooperateData.SupplierName"></label-span>
                <label-span label="供应商纳税人识别号：" :span="cooperateData.SupplierTaxNo"></label-span>
            </div>
        </div>
        <div :class="processed == false?'dialog-footer-left':'dialog-footer-right'" slot="footer">
            <div v-if="processed" :class="'info-operate-'+ data.resultType" class="operate-button">
                <span>已被</span>
                <span style="margin: 0 4px">{{data.handler}}</span>
                <span>{{data.resultType == 'success' ? '同意':'拒绝'}}</span>
            </div>
            <div>
                <div v-if="processed">
                    <el-button type="primary" @click="visible = false">我知道了</el-button>
                </div>
                <div v-else>
                    <el-button @click="handleProcessedToDoList('refuse')">拒绝</el-button>
                    <el-button type="primary" @click="handleProcessedToDoList('agree')">同意</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {updateToDoListProcessed} from "@/service/to-do-list";

    export default {
        name: "CooperateSupplierToRetailer",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                title: '供应商向您申请零供协同',
                visible: false,
                failVisible: false,
                failContent: ''
            }
        },
        watch: {
            show() {
                this.visible = this.show;
            }
        },
        computed: {
            cooperateData() {
                if (typeof (this.data.data) == "undefined") {
                    return {
                        retailerName: "",
                        supplierName: "",
                        orgName: "",
                        orgTaxNo: ""
                    };
                } else {
                    return JSON.parse(this.data.data);
                }
            },
            processed() {
                return this.data.processed;
            }
        },
        methods: {
            /* 待办事项处理 */
            handleProcessedToDoList(type) {
                this.visible = false;
                let processedResult = type == 'agree' ? '已同意' : "已拒绝";
                let resultType = type == 'agree' ? 'success' : "failure";
                updateToDoListProcessed({
                    id: this.data.id,
                    type: this.data.type,
                    processedResult: processedResult,
                    resultType: resultType
                }).then(res => {
                    if (res.success) {
                        if (res.data.result) {
                            this.toast(res.data.message, 'success');
                        }
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog-body {
        text-align: left;

        .label-span {
            margin-bottom: 12px;
        }
    }

    .dialog-footer-right {
        display: flex;
        justify-content: space-between;
    }

    .dialog-footer-left {
        display: flex;
        justify-content: flex-end;
    }

    ::v-deep .el-dialog__header {
        padding: 24px 24px 0 24px;
    }

    ::v-deep .el-dialog__body {
        padding: 24px;
    }

    ::v-deep .el-dialog__footer {
        padding: 0px 24px 24px 24px;
    }
</style>

