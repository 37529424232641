<template>
    <div>
        <el-menu class="el-menu-vertical-demo"
                 :collapse="isCollapse"
                 :default-active="defaultActive"
                 unique-opened
                 router>
            <menu-item :tree="scopeRoutes"></menu-item>
        </el-menu>
    </div>
</template>

<script>
    import MenuItem from "./MenuItem";

    export default {
        name: "NavMenu",
        components: {
            MenuItem
        },
        props: ["isCollapse"],
        data() {
            return {
                defaultActive: "",
                scopeRoutes: this.$store.state.permission.funcList
            };
        },
        watch: {
            "$route": {
                handler() {
                    const code = this.$route.meta.code.substring(7);
                    code !== "RB" ? this.defaultActive = this.$route.path : this.defaultActive = "/open/issue";
                },
                immediate: true
            }
        }
    };
</script>

<style lang="scss" scoped>

    .active_menu_item {
        background-color: #1890ff;
        opacity: 1;
    }
</style>
