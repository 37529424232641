import {get, post} from "./index.js";

/**
 * 获取待办事项列表
 */
export const getSystemToDoList = () => {
    return get("/system/getSystemToDoList", {}).then(res => res);
}
/**
 * 获取待办事项详情
 * @param {*} data
 */
export const getSystemToDoListById = (data) => {
    return get("/system/getSystemToDoListById/" + data).then(res => res);
}
/**
 * 待办事项已读更新
 * @param {*} data
 */
export const updateToDoListRead = (data) => {
    return get("/system/updateToDoListRead/" + data).then(res => res);
}
/**
 * 是否存在未读待办事项
 */
export const getToDoListInfo = () => {
    return get("/system/getToDoListInfo", {}).then(res => res);
}
/**
 * 处理待办信息
 */
export const updateToDoListProcessed = (data) => {
    return post("/system/updateToDoListProcessed", data).then(res => res);
}


