<template>
    <div class="message-frame">
        <div class="frame-item" v-for="message in messages" :key="messages.id" @click="handleReadToDoList(message)">
            <div class="item-content">
                <div :class="message.readStatus ? 'read' : 'unread'"></div>
                <div class="title" v-html="message.title"></div>
            </div>
            <div class="item-operate">
                <div class="operate-time">{{handleShowTime(message.created)}}</div>
                <div class="operate-result" :class="'info-operate-'+ message.resultType">
                    {{message.processedResult}}
                </div>
            </div>
        </div>
        <CooperateToDoList :show.sync="retailerVisible" :data="toDoListData"></CooperateToDoList>
        <CooperateSupplierToRetailer :show.sync="supplierVisible" :data="toDoListData"></CooperateSupplierToRetailer>
        <OrganizationJoinApply :show.sync="applyVisible" :data="toDoListData"></OrganizationJoinApply>
    </div>
</template>

<script>
    import CooperateToDoList from "@/components/todolist/CooperateToDoList";
    import CooperateSupplierToRetailer from "@/components/todolist/CooperateSupplierToRetailer";
    import OrganizationJoinApply from "@/components/todolist/OrganizationJoinApply";
    import {
        updateToDoListRead
    } from '@/service/to-do-list';

    export default {
        name: "MessageFrame",
        components: {CooperateToDoList, OrganizationJoinApply,CooperateSupplierToRetailer},
        props: {
            messages: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                retailerVisible: false,
                supplierVisible: false,
                applyVisible: false,
                toDoListData: {}
            }
        },
        methods: {
            /* 待办事项已读 */
            handleReadToDoList(toDoList) {
                this.toDoListData = toDoList;
                switch (toDoList.type) {
                    case "COOPERATE_PC":
                        this.retailerVisible = true;
                        break;
                    case "COOPERATE_MP":
                        this.supplierVisible = true;
                        break;
                    case "JOIN_ORGANIZATION":
                        this.applyVisible = true;
                        break;
                    default:
                        break;
                }
                this.$emit("close");
                if (!toDoList.readStatus) {
                    updateToDoListRead(toDoList.id);
                }
            },
            /* 展示时间 */
            handleShowTime(time) {
                let date = typeof time === "number" ?
                    new Date(time) : new Date((time || "").replace(/-/g, "/"));
                let diff = (new Date().getTime() - date.getTime()) / 1000;
                let dayDiff = Math.floor(diff / 86400);

                let isValidDate =
                    Object.prototype.toString.call(date) === "[object Date]"
                    && !isNaN(date.getTime());

                if (!isValidDate) {
                    window.console.error("不是有效日期格式");
                }
                const formatDate = function (date) {
                    let today = new Date(date);
                    let month = ("0" + (today.getMonth() + 1)).slice(-2);
                    let day = ("0" + today.getDate()).slice(-2);
                    return `${month}月${day}日`;
                };
                //小于0或者大于等于31显示原时间
                if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
                    return formatDate(date);
                }
                return (
                    (dayDiff === 0 && ((diff < 60 && "刚刚") ||
                        (diff < 120 && "1分钟前") ||
                        (diff < 3600 && Math.floor(diff / 60) + "分钟前") ||
                        (diff < 7200 && "1小时前") ||
                        (diff < 86400 && Math.floor(diff / 3600) + "小时前"))) ||
                    (dayDiff === 1 && "昨天") ||
                    (dayDiff < 7 && dayDiff + "天前") ||
                    (dayDiff < 31 && Math.ceil(dayDiff / 7) + "周前")
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .message-frame {
        margin-top: 14px;
        height: 260px;
        overflow-y: auto;

        .frame-item {
            display: flex;
            justify-content: space-between;
            max-height: 40px;
            margin-bottom: 24px;

            .item-content {
                display: flex;
                justify-content: space-between;

                .unread {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #F5222D;
                    margin-top: 5px;
                    margin-right: 5px;
                }

                .read {
                    width: 8px;
                    height: 8px;
                    margin-right: 5px;
                }

                .title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-align: left;
                }
            }

            .item-operate {
                display: flex;
                flex-direction: column;
                text-align: right;
                width: 56px;
                margin-left: 18px;

                .operate-time {
                    width: 56px;
                    text-align: right;
                    margin-bottom: 6px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                }

                .operate-result {
                    width: 56px;
                    text-align: right;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    line-height: 17px;
                }
            }
        }
    }
</style>
