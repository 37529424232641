<template>
  <el-select
    filterable
    v-model="model"
    placeholder="切换主体"
    @change="handleChange"
    :disabled="loading"
    size="mini"
  >
    <el-option
      v-for="item in orgList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import store from "@/store";
import { Message } from "element-ui";
import { getCurrentOrg } from "@/service/sys/user";
export default {
  name: "GlobalOrganization",
  data() {
    return {
      model: null,
      orgList: [],
      loading: false,
    };
  },
  created() {
    this.loading = true;
    store.dispatch("sys/LoadOrgs").then(() => {
      this.orgList = store.state.sys.orgs;
      getCurrentOrg().then((res) => {
        // console.log("getCurrentOrg：", res);
        this.model = res.data;
        this.loading = false;
      });
    });
  },
  methods: {
    handleChange() {
      this.loading = true;
      store.dispatch("sys/ChangeOrg", this.model).then(() => {
        Message({
          message: "切换主体成功，请您刷新页面",
          type: "success",
          duration: 3000,
        });
        this.loading = false;
      });
    },
  },
};
</script>
