<template>
    <div class="to-do-list" ref="toDoList">
        <el-popover
                v-show="isHasMessage"
                v-model="visible"
                placement="bottom-end"
                :offset="60"
                width="420"
                trigger="manual"
                @show="handlePopoverShow"
                @hide="handlePopoverHide"
        >
            <el-badge slot="reference" :value="unread" :max="99">
                <img src="@/assets/img/resources/Hover.png" width="20" height="20" @click="handleGetToDoList"/>
            </el-badge>
            <el-tabs v-model="active">
                <el-tab-pane :label="'全部消息（'+messages.length+'）'" name="first">
                    <message-frame :messages="messages" @close="handleCloseFrame"/>
                </el-tab-pane>
                <el-tab-pane :label="'协同消息（'+cooperates.length+'）'" name="second">
                    <message-frame :messages="cooperates" @close="handleCloseFrame"/>
                </el-tab-pane>
                <el-tab-pane :label="'成员消息（'+members.length+'）'" name="third">
                    <message-frame :messages="members" @close="handleCloseFrame"/>
                </el-tab-pane>
            </el-tabs>
        </el-popover>
        <el-popover v-show="!isHasMessage" placement="bottom-end" :offset="60" width="360" trigger="click">
            <div class="without">
                <img src="@/assets/img/resources/no_message.png"/>
                <span>没有任何通知</span>
            </div>
            <img slot="reference" src="@/assets/img/resources/Default.png" width="20" height="20">
        </el-popover>
    </div>
</template>

<script>
    import MessageFrame from "@/components/todolist/MessageFrame";
    import {getSystemToDoList, getToDoListInfo} from "@/service/to-do-list";
    import {constantsWs} from "@/service/ws-constants";

    export default {
        name: "ToDoList",
        components: {MessageFrame},
        data() {
            return {
                active: 'first',
                visible: false,
                isHasMessage: true,
                unread: 0,
                messages: []
            }
        },
        created() {
            this.$bus.$on(constantsWs.TODO, (params) => {  //获取传递的参数并进行操作
                this.handleGetToDoNumbers();
            })
        },
        computed: {
            cooperates() {
                return this.messages.filter(message => ["COOPERATE_MP", "COOPERATE_PC"].includes(message.type));
            },
            members() {
                return this.messages.filter(message => message.type == 'JOIN_ORGANIZATION');
            }
        },
        mounted() {
            this.handleGetToDoNumbers();
        },
        methods: {
            // Popover 弹出框显示
            handlePopoverShow() {
                document.addEventListener('click', this.handleHidePanel, false)
            },
            // Popover 弹出框隐藏
            handlePopoverHide() {
                document.removeEventListener('click', this.handleHidePanel, false)
            },
            // 隐藏面板
            handleHidePanel(e) {
                if (!this.$refs.toDoList.contains(e.target)) {
                    this.visible = false
                    this.handlePopoverHide()
                }
            },
            // 未读待办事项数量
            handleGetToDoNumbers() {
                getToDoListInfo().then(res => {
                    if (res.success) {
                        let totals = res.data.totals;
                        this.isHasMessage = totals > 0;
                        this.unread = res.data.unread;
                    }
                });
            },
            // 已读事件处理
            handleCloseFrame() {
                this.visible = false
                this.handleGetToDoNumbers()
            },
            // 获取待办消息列表
            handleGetToDoList() {
                this.visible = !this.visible;
                getSystemToDoList().then(res => {
                    if (res.success) {
                        this.messages = res.data;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .to-do-list {
        margin-left: 24px;
    }


    .without {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 88px;
            height: 86px;
            margin-top: 68px;
            margin-bottom: 12px;
        }

        span {
            width: 84px;
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            margin-bottom: 70px;
        }
    }

</style>
