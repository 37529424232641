import { render, staticRenderFns } from "./OrganizationJoinApply.vue?vue&type=template&id=225c1fd4&scoped=true"
import script from "./OrganizationJoinApply.vue?vue&type=script&lang=js"
export * from "./OrganizationJoinApply.vue?vue&type=script&lang=js"
import style0 from "./OrganizationJoinApply.vue?vue&type=style&index=0&id=225c1fd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225c1fd4",
  null
  
)

export default component.exports